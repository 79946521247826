import React, { useState } from 'react';
import CustomNavbar from './components/Navbar';
import Quem from './components/Quem';
import Formacao from './components/Formacao';
import Atendimento from './components/Atendimento';
import Publicacoes from './components/Publicacoes';
import Contato from './components/Contato';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

function App() {
  const [activeSection, setActiveSection] = useState('Header');

  let content;
  switch (activeSection) {
    case 'Header':
      content = <Header />;
      break;
    case 'Quem':
      content = <Quem />;
      break;
    case 'Formacao':
      content = <Formacao />;
      break;
    case 'Atendimento':
      content = <Atendimento />;
      break;
    case 'Publicacoes':
      content = <Publicacoes />;
      break;
    case 'Contato':
      content = <Contato />;
      break;
    default:
      content = <Header />;
  }

  return (
    <div>
      <CustomNavbar setActiveSection={setActiveSection} />
      {content}
      <Footer />
    </div>
  );
}

export default App;
