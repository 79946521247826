import React from 'react';

function Publicacoes() {
    return (
        <div>
            <h2 className="m-3">Publicações</h2>

            <p className="m-5 justificar-texto">
                <a className="text-dark" target='_blank' href="https://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K9187719A2&tokenCaptchar=03AFcWeA6LUzYFCefs8zQhTfgDDdC8ceVMPOyb77B9qVCyOR-EGsP-YM7K9M4Ks_aFF7aajsCFiSCsgZ7j-moMGcfvRkr3iL3HAcfv9sM1KAx5_G51K2wRarAEjVCHFqNnOBjdDYZu1cPlUWHcZz0V_WdiKHZUxDskh6qgc6LnhSSM9-qR-J8nZRMd4dnUkMTwe7TSmOHgbJ9HiEquTZYarCF3ECv7VKVXSh592LHv3o0ACn2dhEkuqFWFJUtn85ZDHoTmP3uyP_2yPPV9MMWNd7_KbwC9bhuVcXx5fsaAVZhuBfxXocNimqIJ_nDswAGAEg-wODyKKXxGQawRHy3UqKnUKu9zWpFhlYgaItNgfpy_OXxNwfXs6zuQDgeSq3bNZogO7mD8gtaRXWnHJ2oDk39nnoicRgDgICoYxYoSJ_7jAYRr2hPTrervjZTkKV3O1C0PXh9t-GCuZ4FymvM4SKxjR7udtYL91DOirNbI1trCTWArxA0TCw4g1EturHXXLu6QxzV_R_2JYop2szTtt3WdNBAUh8TyR3G4a3LMgAX5n0301MYHZEljkMq2CDbrL9rDfVMPsLwp5NGTAvhwuRWitbvRTIzFWmM1qbj67m-rxLThgjE6Ul3FSu687nnq8WmbNN1hxzPOgmEfLLw4-FTb5McNMOGGh4VWiDfj-2_rrS6C70I0ZsWIXdC24zVqMf__-By52J0eOAS85BtFdaNDaZe1GxMeaITk0bZVLeSvpO51bs_IPATEpU5mstkUBhlFldrlrfx016vorw7kY3WCHU-F4ugT2QQA1r0E8H5_VkRnJRPq4VUfwKg6DPMX-TxulCmg9IYbHtI4aGO6SPu7hs3DHuoi3n4ftqfxEpzolSkANVhUfmoBjN1dLTf5BcRY1kq0B_JS0abX0uxLY9HidAStJBJ3AkjXGjnMDxpLOO1ku_xcxLQ">
                    Currículo Lattes</a></p>

            <p className="m-5 justificar-texto">
                <a className="text-dark" target='_blank' href="https://drauziovarella.uol.com.br/podcasts/drauziocast/como-fazer-um-atendimento-medico-inclusivo/amp/">
                Como fazer um atendimento médico inclusivo? – DrauzioCast #196</a></p>
                    
            <p className="m-5 justificar-texto">
                <a className="text-dark" target='_blank' href="https://cefi.phl.bib.br/uploads/arquivo/1718126206.pdf">
                    QUANDO O SILÊNCIO ECOA: O MANEJO TERAPÊUTICO SISTÊMICO DO SEGREDO EM INTERFACE COM O HIV/AIDS</a></p>

            <p className="m-5 justificar-texto">
                <a className="text-dark" target='_blank' href="https://publicacoes.abennacional.org.br/wp-content/uploads/2023/03/C10-Manoela-Coimbra-de-Medeiros.pdf">
                    COMO UMA ORGANIZAÇÃO NÃO-GOVERNAMENTAL PODE CONTRIBUIR PARA AS BOAS PRÁTICAS EM SAÚDE COM A POPULAÇÃO LGBTI+?</a></p>

        </div>
    );
}

export default Publicacoes;
