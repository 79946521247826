import React from 'react';

function Formacao() {
  return (
    <div >
      <h2 className="m-3">Formação</h2>
      <p className="m-5 justificar-texto">Psicóloga formada pela Cesuca - Centro Universitário (2020) enquanto prounista. Possui especialização em Terapia Sistêmica pelo Centro de Estudos da Família e do Indivíduo - CEFI  (2023), tendo sido qualificada  para o atendimento de indivíduos, casais (relações) e famílias.</p>
      <p className="m-5 justificar-texto">Também é especialista em Educação Social e Direitos Humanos pela Universidade Federal do Rio Grande do Sul - UFRGS (2024). Atualmente faz parte do Programa LBQ Connect - OutRight Internacional, que propõe a qualificação de ativistas lésbicas, bissexuais e queer de todo o mundo.</p>

      <div className="col-md-5 p-lg-5  m-5 ">
        <img src="img-mano.jpeg" className="img-fluid rounded-5" alt="..."></img>

      </div>
      <div className="">
        <h2 className="m-3 mt-2">Atuação</h2>
        <p className="m-5 justificar-texto">Desde 2018 venho me dedicando ao trabalho com as populações LGBTI+ e pessoas vivendo com HIV/AIDS. Iniciei essa trajetória pela inserção no G8-Generalizando, um grupo da Faculdade de Direito da Universidade Federal do Rio Grande do Sul, na mesma época também iniciei meu estágio de conclusão de curso em um Serviço de Atendimento Especializado para pessoas vivendo com HIV/AIDS do SUS.</p>
        <p className="m-5 justificar-texto">Fui organizadora voluntária da Parada Livre de Porto Alegre e da Jornada Lésbica Feminista Antirracista, importantes espaços de militância local. Representei o Conselho de Psicologia do RS no Conselho Estadual LGBT. Estive monitora do curso de aperfeiçoamento em Teoria Sistêmica do CEFI, atualmente participo dos Comitês de Ética do Hospital Moinhos de Vento e do Instituto de Psicologia, Serviço Social, Saúde e Comunicação Humana – UFRGS representando a <a className="text-dark" target='_blank' href="https://www.somos.org.br">ONG Somos</a>.</p>
        <p className="m-5 justificar-texto">Sou voluntária na ONG Somos desde 2020, já estive coordenadora do serviço de atendimento e serviço de psicologia da instituição, participei de projetos diversos, facilitei grupos de convivência, supervisei estágio e atualmente estou uma das diretoras da instituição. Desde 2020 dedico-me à prática clínica virtual de pessoas adultas em terapias individuais ou de relações(casais). Também possuindo experiência com formações sobre diversidade sexual e de gênero e sexualidade para outres profissionais da psicologia, profissionais da saúde e da assistência social e empresas.</p>
        {/* <p className="m-5 justificar-texto"></p> */}
      </div>
    </div>
  );
}

export default Formacao;
