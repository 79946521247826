import React from 'react';

function Quem() {
    return (
        <div className="position-relative overflow-hidden p-1 p-md-5 m-md-3 text-center bg-light row align-items-center">
            <div className="col-md-5 p-lg-5  mx-auto my-3">
                <img src="desenho-2.png" className="img-fluid rounded-circle" alt="..."></img>
            </div>
            <div className="col-md-5 p-lg-5 mx-auto my-3">
                <h2 className="m-3">Quem sou eu</h2>
                <p className="m-5 justificar-texto">Inquieta e afetuosa, sapatona que tem construído muito de sua identidade profissional também nos espaços de ativismo LGBTI+. Atualmente é uma das diretoras da <a className="text-dark" target='_blank' href="https://www.somos.org.br">ONG Somos - Comunicação, Saúde e Sexualidade</a>, tendo desde 2020 dedicado-se em projetos variados, supervisão de estágio de psicologia e facilitação de grupos de convivência.</p>
                <p className="m-5 justificar-texto">Possui experiência de trabalho em serviços do SUS no atendimento especializado às pessoas vivendo com HIV/AIDS. Apaixonada por poesia e pelo movimento do corpo, experimentando-se nas práticas de pole dance e corrida. Moradora da cidade de Porto Alegre - RS, mas com criação na região metropolitana, o que lhe traz um olhar atento as questões de território e socioeconômicas.</p>

            </div>
            
        </div>

    );
}

export default Quem;
